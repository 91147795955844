import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Icon from '@fortawesome/free-solid-svg-icons'

//import './App.css';

class Box extends React.Component {
  render (){
    return (
      <div className="Box">
        <FontAwesomeIcon className="Icon-size" icon={Icon[this.props.icon]} />
        <div className="Box-content">{this.props.children}</div>
      </div>
    );
  }
}

export default Box;