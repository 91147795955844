import React from 'react';
//import './App.css';

class Section extends React.Component {
  sectionRef = React.createRef();
  render (){
    return (
      <div ref={this.sectionRef} className="Section">
        {this.props.children}
      </div>
    );
  }

  getYPosition(){
    return this.sectionRef.current.offsetTop;
  }
}

export default Section;