import React from "react";
import "./App.css";
import Section from "./Section.js";
import Box from "./Box.js";
import Form from "./Form.js";

class App extends React.Component {
  serviceRef = React.createRef();
  contactRef = React.createRef();
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <ul className="Side-list">
            <li>
              <span onClick={this.click("service")}>Services</span>
            </li>
            <li>
              <span onClick={this.click("contact")}>Contact</span>
            </li>
          </ul>
        </div>
        <div className="Banner-div" />
        <div className="Logo-top">
          <img src="/logo-top.png" />
        </div>
        <div>
          <Section>
            <div className="Section-license Blue-background">
              <div id="name">Monte Valentine</div>
              <div>PROFESSIONAL TEXAS REAL ESTATE INSPECTOR</div>
              <div>
                License Number: <strong>TREC# 23778</strong>
              </div>
            </div>
          </Section>
          <Section ref={this.serviceRef}>
            <div className="Section Section-padding">
              <div className="Header-text">
                Welcome to Eagle Eye Home Inspections Services
              </div>
              <div className="Mid-Sections">
                <div className="Half-width">
                  <div>We provide comprehensive inspections for:</div>
                  <br></br>
                  <div>
                    <ul>
                      <li>Commercial Facilitices</li>
                      <li>Inspections after Repairs</li>
                      <li>New Home Phase Inspections</li>
                      <li>Pool & Spa Inspection</li>
                      <li>Residential</li>
                      <li>Seller Inspection</li>
                      <li>Thermal Imaging</li>
                      <li>Water Sprinkler Inspection</li>
                      <li>Zip Level Foundation Inspection</li>
                    </ul>
                  </div>
                  <div className="Header-text">Service Areas</div>
                  <div>
                    <ul>
                      <li>Austin County</li>
                      <li>Brazoria County</li>
                      <li>Colorado County</li>
                      <li>Fort Bend County</li>
                      <li>Waller County</li>
                      <li>West Harris County</li>
                      <li>Wharton County</li>
                    </ul>
                  </div>
                </div>
                <div className="Half-width">
                  <Box icon="faListUl">
                    <div>
                      <strong>COMPREHENSIVE REPORT</strong>
                      <div>
                        With Eagle Eye Home Inspection Services, you will
                        receive a comprehensive report within 24 hours detailing
                        the condition of your property.
                      </div>
                    </div>
                  </Box>
                  <Box icon="faHandshake">
                    <div>
                      <strong>WE WORK WITH YOU</strong>
                      <div>
                        During the last 30 mintues of the inspection, we
                        recommend that you be on site for a more personal
                        experience.
                      </div>
                    </div>
                  </Box>
                  <Box icon="faCheckCircle">
                    <div>
                      <strong>TRUSTED RESULTS</strong>
                      <div>
                        After your inspection you will have peace of mind about
                        the condition of your property to plan for needed
                        repairs.
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="Header-text">What We Inspect</div>
              <div className="Quarter-width">
                <strong className="Quarter-header">Structural</strong>
                <div>
                  <ul>
                    <li>Foundations</li>
                    <li>Roofing and associated fascia</li>
                    <li>Wall and Roof Structural</li>
                    <li>Yard grade and drainage</li>
                    <li>Interior and exterior walls, door and windows</li>
                    <li>Attached porches, decks, carports and awings</li>
                    <li>Fireplace structure, funcation and flashing</li>
                  </ul>
                </div>
              </div>
              <div className="Quarter-width">
                <strong className="Quarter-header">
                  Mechanical (AC/Heat & Plumbing
                </strong>
                <div>
                  <ul>
                    <li>Heating equipment and exhaust</li>
                    <li>A/C system inside and outside units</li>
                    <li>Bathroom exhaust systems</li>
                    <li>Dryer ventilation</li>
                    <li>Duct work and drains</li>
                    <li>Plumbing fixtures</li>
                    <li>Drains, disposals and air vents</li>
                    <li>Water heaters</li>
                    <li>Specialty tuvbs</li>
                  </ul>
                </div>
              </div>
              <div className="Quarter-width">
                <strong className="Quarter-header">Electrical System</strong>
                <div>
                  <ul>
                    <li>Service panel</li>
                    <li>Service disconnects</li>
                    <li>Proper grounding</li>
                    <li>
                      Check for adequate grounding in outlets and circuits
                    </li>
                    <li>Sub panel and circuits</li>
                    <li>Breakers</li>
                  </ul>
                </div>
              </div>
              <div className="Quarter-width">
                <strong className="Quarter-header">Appliances</strong>
                <div>
                  <ul>
                    <li>Dishwashers and disposals</li>
                    <li>Stove ventilation and exhaust</li>
                    <li>
                      Ovens and all cooking equipment hard wired to the house
                    </li>
                    <li>Trash compactors and ice machines</li>
                    <li>Garage door operations</li>
                    <li>Hot Water Heaters</li>
                  </ul>
                </div>
              </div>
            </div>
          </Section>
          <Section ref={this.contactRef}>
            <div className="Section Section-padding Blue-background">
              <div className="Contact-header">Contact us for a free quote!</div>
              <div className="Mid-Sections Add-Wrap">
                <Form></Form>
                <div className="Half-width">
                  <div className="Contact-info">
                    contact@eeinspectionservices.com
                  </div>
                  <div className="Contact-info">
                    Call us directly: <br></br>
                    281-736-5472
                  </div>
                  <div className="Contact-info">
                    When discussing your property, we will need to know the
                    year, square footage, if it is a single story or two story,
                    and the location.
                  </div>
                </div>
              </div>
            </div>
          </Section>
          <div className="Section Bottom-padding">
            <div className="Mid-Sections Add-Wrap">
              <div className="Half-width">
                <div className="Bottom-header">Accepted Payments</div>
                <div>We accept the following payment methods:</div>
                <div className="Payment-Types">
                  <span>
                    <img className="Payment-img" src="/cash.png" />
                  </span>
                  <span>
                    <img className="Payment-img" src="/check.png" />
                  </span>
                  <span>
                    <img className="Payment-img" src="/venmo.png" />
                  </span>
                  <span>
                    <img className="Payment-img" src="/zelle.png" />
                  </span>
                </div>
              </div>
              <div className="Half-width">
                <div className="Bottom-header">Associations</div>
                <img className="Association-img" src="/associations.png" />
              </div>
            </div>
          </div>
          <footer className="Footer">
            <div>
              <a
                href="https://www.trec.texas.gov/forms/consumer-protection-notice"
                target="_blank"
              >
                Texas Real Estate Commision Consumer Protection Notice
              </a>
            </div>
            <div>
              &copy;2019 Eagle Eye Inspection Services. ALL RIGHTS RESERVED.
            </div>
          </footer>
        </div>
      </div>
    );
  }

  click = (section) => () => {
    const yPos = this[section + "Ref"].current.getYPosition();
    window.scrollTo(0, yPos);
  };
}

export default App;
