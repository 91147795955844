import { faExclamationCircle, faSun } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import validator from "validator";

//import './App.css';

class Form extends React.Component {
  defaultState = () => {
    return {
      fullname: "",
      email: "",
      phone: "",
      addressStreet: "",
      city: "",
      state: "TX",
      zipCode: "",
      message: "",
      errors: {},
      formSent: "",
    };
  };
  state = this.defaultState();
  onSubmitClick = async () => {
    let errorArray = {};
    let fields = [
      "fullname",
      "email",
      "phone",
      "addressStreet",
      "city",
      "state",
      "zipCode",
      "message",
    ];
    let hasError = false;
    function validate(item, itemValue) {
      if (!item) {
        console.log(`error: ${item} is requried`);
        return true;
      }
      if (item === "email") {
        return !validator.isEmail(itemValue);
      } else if (item === "phone") {
        return !validator.isMobilePhone(itemValue);
      } else if (item === "zipCode") {
        return !validator.isLength(itemValue, { min: 5, max: 5 });
      } else {
        return validator.isEmpty(itemValue);
      }
    }
    fields.forEach((item) => {
      hasError = validate(item, this.state[item] || "");
      if (hasError) {
        errorArray[item] = true;
        console.log(`${item}, has an error`);
      }
    });
    this.setState({ errors: errorArray });
    if (Object.keys(errorArray).length === 0) {
      let formData = { ...this.state };
      delete formData.errors;
      delete formData.formSent;
      const response = await fetch("https://formspree.io/f/mvovglpq", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const formResponse = await response.json();
      if (formResponse.ok) {
        console.log("Form successfully sent");
        this.setState(this.defaultState());
        this.setState({ formSent: "Email succefully sent!" });
      } else {
        this.setState({ formSent: "Sorry, server error please submit again!" });
        console.log("Sorry, server Error please try submitting again!");
      }
    }
  };
  changeform = (field) => (e) => {
    this.setState({
      [field]: e.target.value,
    });
  };
  render() {
    return (
      <div className="Form Half-width">
        <div className={`Inline ${this.state.errors.fullname ? "error" : ""}`}>
          <div>Full Name*</div>
          <input
            onChange={this.changeform("fullname")}
            value={this.state.fullname}
            className="Input"
          />
        </div>
        <div className={`Inline ${this.state.errors.email ? "error" : ""}`}>
          <div>Email*</div>
          <input
            onChange={this.changeform("email")}
            value={this.state.email}
            className="Input"
          />
        </div>
        <div className={`Inline ${this.state.errors.phone ? "error" : ""}`}>
          <div>Phone*</div>
          <input
            onChange={this.changeform("phone")}
            value={this.state.phone}
            className="Input"
          ></input>
        </div>
        <div
          className={`Inline ${
            this.state.errors.addressStreet ||
            this.state.errors.city ||
            this.state.errors.state ||
            this.state.errors.zipCode
              ? "error"
              : ""
          }`}
        >
          <div>Address*</div>
          <input
            onChange={this.changeform("addressStreet")}
            value={this.state.addressStreet}
            className="Input"
          />
          <div className="Form-label-sub">Street Address</div>
          <div className="city-state-element">
            <input
              onChange={this.changeform("city")}
              value={this.state.city}
              className="Input city-state"
              id="city"
            />
            <select
              onChange={this.changeform("state")}
              value={this.state.state}
              className="city-state"
              id="state"
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div className="city-state-label">
            <div className="Form-label-sub">City</div>
            <div className="Form-label-sub" id="stateLabel">
              State
            </div>
          </div>
          <input
            onChange={this.changeform("zipCode")}
            value={this.state.zipCode}
            className="Input"
          />
          <div className="Form-label-sub">ZIP Code</div>
        </div>
        <div className={`${this.state.errors.message ? "error" : ""}`}>
          <div>Message*</div>
          <textarea
            onChange={this.changeform("message")}
            value={this.state.message}
            className="Input-large"
            rows="8"
          />
        </div>
        <div className="Button-container">
          <span id="form-sent">{this.state.formSent}</span>
          <button
            onClick={this.onSubmitClick}
            id="submitEl"
            className="Button Darkblue-background"
          >
            SUBMIT
          </button>
        </div>
      </div>
    );
  }
}

export default Form;
